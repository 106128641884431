import React from "react"
import { graphql } from "gatsby"
import RichText from "@madebyconnor/rich-text-to-jsx"

export const data = graphql`
  query getImpressum {
    impressum: contentfulImpressumImpressumRichTextNode {
      json
    }
  }
`

const ImpressumPage = ({ data }) => {
  const {
    impressum: { json },
  } = data
  return (
    <div>
      <RichText richText={json} />
    </div>
  )
}

export default ImpressumPage
